<template>
  <div>
    <div id="container"></div>
  </div>
</template>

<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
// import { PointerLockControls } from "three/examples/jsm/controls/PointerLockControls.js";
// import { MTLLoader } from "three-obj-mtl-loader";
// import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
// import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
// import { SkeletonUtils } from "three/examples/jsm/utils/three/examples/jsm/controls/SkeletonUtils";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
// import { TWEEN } from "three/examples/jsm/libs/tween.module.js";
import * as TWEEN from "@tweenjs/tween.js";
// import { TextureLoader } from "three/examples/jsm/loaders/TextureLoader";
export default {
  name: "ThreeTest",
  data() {
    return {
      camera: null,
      scene: null,
      renderer: null,
      intersections: null,
      // decoderPath: "three/examples/jsm/libs/draco/gltf",
      standPosition: [0.0386637, 15.9561, 0.01501],
      standRotation: [0, 0, (1.9161604 * 180) / Math.PI],
      mixers: [],
      container: null,
      guideAnimation: null,
      walkSpeed: 1,
      walkingman: null,
      walkingAnimation: null,
      stopAnimation: null,
      tween: null,
      clock: new THREE.Clock(),
    };
  },
  methods: {
    init() {
      this.container = document.getElementById("container");
      this.initScene(); //场景对象
      this.initCamera(); //相机
      this.initWebGLRenderer(); //渲染器
      this.initAxisHelper(); //辅助坐标
      this.initPlane(); //地板
      this.initLight();
       this.Box();
      this.initOrbitControl(); //初始化控制器
      this.tween = TWEEN;
      this.render();
      // this.createControls(); //控件对象
      // this.initControls(); //相机视角
      this.container.addEventListener(
        "click",
        this.mouseClick.bind(this),
        false
      );
      this.container.addEventListener(
        "touchstart",
        this.touchStart.bind(this),
        false
      );
      this.container.addEventListener(
        "touchend",
        this.touchEnd.bind(this),
        false
      );
      this.container.addEventListener(
        "mousemove",
        this.mouseMove.bind(this),
        false
      );
      this.container.addEventListener(
        "mousedown",
        this.mouseDown.bind(this),
        false
      );
    },
    mouseClick(evt) {
      if (this.mouseHasMove) return;
      let container = this.container;
      let bounding = container.getBoundingClientRect(),
        pixel = [
          evt.clientX - bounding.left - container.clientLeft,
          evt.clientY - bounding.top - container.clientTop,
        ];
      this.handleClick(pixel);
    },
    Box() {
      //正方形
      let geometry = new THREE.BoxGeometry(5, 5, 5);
      let material = new THREE.MeshNormalMaterial();
      this.mesh = new THREE.Mesh(geometry, material);
      this.scene.add(this.mesh);
      this.mesh.position.set(0,10,0)
    },
    //射线相交的方式来获取点击的坐标 ，
    // 点击到模型中的地面后，
    // 根据点击处的坐标，获取人物行走的目标点
    handleClick(pixel) {
      let intersects = this.getObjectsAtPixel(pixel);
      if (intersects.length != 0) {
        let point = null;
        for (let i = 0; i < intersects.length; i++) {
          let currPoint = intersects[i].point;
          console.log(
            intersects[i].point,
            "intersects[i].point",
            intersects[i].object,
            "intersects[i].object",
            intersects[i]
          );
          console.log(currPoint, "currPoint");
          //  object = intersects[i].object;
          if (currPoint.z < 0.2) {
            point = currPoint;
            console.log(point, "point");
            break;
          }
        }
        if (point) {
          console.log(point, "pointwalkToThere");
          this.walkToThere(point);
        }
      }
    },
    getObjectsAtPixel(pixel) {
      let start = this.camera.position;
      let vector = new THREE.Vector3(); //三维坐标对象
      vector.set(
        (pixel[0] / this.container.clientWidth) * 2 - 1,
        -(pixel[1] / this.container.clientHeight) * 2 + 1,
        0.5
      );
      console.log(vector, "vector");
      vector.unproject(this.camera);
      return this.getObjectsByRay([start, vector]);
    },
    getObjectsByRay(pointArr) {
      if (pointArr.length != 2) return;
      let startOfRay = pointArr[0],
        endOfRay = pointArr[1];
      let detectObjects = this.scene.children;
      var raycaster = new THREE.Raycaster(
        startOfRay,
        endOfRay.sub(startOfRay).normalize()
      );
      var intersects = raycaster.intersectObjects(detectObjects, true);
      return intersects;
    },
    walkToThere(point) {
      console.log(point, "point");
      if (this.guideAnimation) {
        this.guideAnimation.stop();
        this.guideAnimation = null;
      }
      this.turnTo(point);
      let model = this.walkingman,
        position = model.position,
        walkSpeed = this.walkSpeed;
      console.log(model.position, "model.position", this.walkingman, position);
      console.log(model, "model");
      let dist = Math.hypot(
          point.x - position.x,
          point.y - position.y,
          point.z - position.z
        ),
        totalTime = (dist * 1000) / walkSpeed;
      var rotation = -Math.atan2(point.y - position.y, point.x - position.x);
      model.rotation.y = -rotation + Math.PI / 2;
      const me = this;
      me.guideAnimation = new TWEEN.Tween(model.position)
        .to(
          {
            //动画过渡 y 1.8
            x: point.x,
            y: point.y,
            z: point.z,
          },
          totalTime
        )
        .onStart(function () {
          if (me.stopAnimation.enabled) {
            me.walkingAnimation && me.setWeight(me.walkingAnimation, 1);
            me.walkingAnimation.time = 0;
            me.stopAnimation.crossFadeTo(me.walkingAnimation, 0.35, true);
          }
        })
        .onUpdate(function () {
          let modelPos = model.position;
          let eyePosition = [modelPos.x, modelPos.y, modelPos.z + 1.7];
          let originTarget = me.control.target.clone(),
            originPosition = me.camera.position.clone();
          me.camera.position.set(
            eyePosition[0] + originPosition.x - originTarget.x,
            eyePosition[1] + originPosition.y - originTarget.y,
            eyePosition[2] + originPosition.z - originTarget.z
          );
          me.camera.lookAt(
            new THREE.Vector3(eyePosition[0], eyePosition[1], eyePosition[2])
          );
          me.control.target.set(eyePosition[0], eyePosition[1], eyePosition[2]);
          me.control.update();
          let eyeRotation = rotation + Math.PI / 2;
          let currPos = new THREE.Vector3(
              modelPos.x,
              modelPos.y,
              modelPos.z + 0.5
            ),
            nextPos = new THREE.Vector3(
              modelPos.x + 0.5 * Math.sin(eyeRotation),
              modelPos.y + 0.5 * Math.cos(eyeRotation),
              modelPos.z + 0.8
            );
          let crash = me.detectCrash(currPos, nextPos);
          if (crash) {
            me.stopAnimation && me.setWeight(me.stopAnimation, 1);
            me.stopAnimation.time = 0;
            me.walkingAnimation.crossFadeTo(me.stopAnimation, 0.35, true);
            me.guideAnimation.stop();
            me.guideAnimation = null;
          }
        })
        .onComplete(function () {
          me.stopAnimation && me.setWeight(me.stopAnimation, 1);
          me.stopAnimation.time = 0;
          me.walkingAnimation.crossFadeTo(me.stopAnimation, 0.35, true);
        })
        .delay(200);
      me.guideAnimation.start();
    },
    turnTo(point) {
      let model = this.walkingman,
        position = model.position;
      let rotation =
        Math.atan2(point.y - position.y, point.x - position.x) + Math.PI / 2;
      model.rotation.y = model.rotation.y % (Math.PI * 2);
      let manRotation = model.rotation.y;
      if (rotation - manRotation > Math.PI) {
        rotation = rotation - Math.PI * 2;
      }
      if (rotation - manRotation < -Math.PI) {
        rotation = rotation + Math.PI * 2;
      }
      let time = (100 * Math.abs(rotation)) / (Math.PI / 6);
      if (this.turnAnimation) {
        this.turnAnimation.stop();
      }
      this.turnAnimation = new TWEEN.Tween(model.rotation)
        .to(
          {
            //动画过渡 y 1.8
            y: rotation,
          },
          time
        )
        .onStart(function () {})
        .onUpdate(function () {})
        .onComplete(function () {});
      this.turnAnimation.start();
    },

    updateAnimation() {
      let delta = this.clock.getDelta();
      for (let i = 0; i < this.mixers.length; i++) {
        // 重复播放动画
        this.mixers[i].update(delta);
      }
    },

    touchStart() {
      this.mapTouchPrevTime = new Date().getTime();
    },
    touchEnd(evt) {
      let curr = new Date().getTime();
      if (this.mapTouchPrevTime && curr - this.mapTouchPrevTime < 200) {
        let pixel = [evt.changedTouches[0].pageX, evt.changedTouches[0].pageY];
        this.handleClick(pixel);
      }
    },
    setFrustumCulled(model) {
      model.frustumCulled = false;
      for (let child of model.children) {
        this.setFrustumCulled(child);
      }
    },
    setWeight(action, weight) {
      action.enabled = true;
      action.setEffectiveTimeScale(1);
      action.setEffectiveWeight(weight);
    },
    // 初始化控制器
    initOrbitControl() {
      this.control = new OrbitControls(this.camera, this.renderer.domElement); //用户交互
      let standPosition = this.standPosition,
        rotation = this.standRotation;
      let eyePosition = [
        standPosition[0],
        standPosition[1],
        standPosition[2] + 1.7,
      ];
      let eyeRotation = -((rotation[2] * Math.PI) / 180 + Math.PI);
      // let desPosX = eyePosition[0] + Math.sin(eyeRotation), desPosY = eyePosition[1] + Math.cos(eyeRotation);
      this.camera.position.set(
        eyePosition[0] - 2 * Math.sin(eyeRotation),
        eyePosition[1] - 2 * Math.cos(eyeRotation),
        eyePosition[2] + 0.6
      );
      this.camera.lookAt(
        new THREE.Vector3(eyePosition[0], eyePosition[1], eyePosition[2])
      );
      this.control.target.set(eyePosition[0], eyePosition[1], eyePosition[2]);
      this.control.enablePan = false;
      this.control.panSpeed = 1;
      this.control.enableZoom = true;
      this.control.zoomSpeed = 1;
      this.control.enableRotate = true;
      this.control.enableDamping = true;
      this.control.rotateSpeed = 0.1;
      this.control.mouseButtons = {
        LEFT: THREE.MOUSE.LEFT,
        MIDDLE: THREE.MOUSE.MIDDLE,
        RIGHT: THREE.MOUSE.RIGHT,
      };
      //设置相机的角度范围
      this.control.maxPolarAngle = (Math.PI * 2) / 3;
    },
    turnToPoint(point, callback) {
      if (this.guideAnimation) {
        this.guideAnimation.stop();
        this.guideAnimation = null;
      }
      let model = this.walkingman,
        position = model.position;
      let rotation =
        Math.atan2(point.y - position.y, point.x - position.x) + Math.PI / 2;
      let manRotation = model.rotation.y;
      if (rotation - manRotation > Math.PI) {
        rotation = rotation - Math.PI * 2;
      }
      if (rotation - manRotation < -Math.PI) {
        rotation = rotation + Math.PI * 2;
      }
      const me = this;
      this.guideAnimation = new TWEEN.Tween(model.rotation)
        .to(
          {
            //动画过渡 y 1.8
            y: rotation,
          },
          200
        )
        .onStart(function () {
          me.walkingAnimation && me.walkingAnimation.play();
          me.stopAnimation && me.stopAnimation.stop();
        })
        .onUpdate(function () {
          console.log(model.rotation);
        })
        .onComplete(function () {
          if (callback) callback();
        });
      this.guideAnimation.start();
    },

    detectCrash(curr, next) {
      let intersects = this.getObjectsByRay([curr.clone(), next.clone()]);
      if (intersects.length != 0) {
        let nextIn = [];
        for (let i = 0; i < intersects.length; i++) {
          let currPoint = intersects[i].point;
          if (
            (currPoint.x - curr.x) / (next.x - curr.x) < 1.0 &&
            (currPoint.x - curr.x) / (next.x - curr.x) > 0 &&
            (currPoint.y - curr.y) / (next.y - curr.y) < 1.0 &&
            (currPoint.y - curr.y) / (next.y - curr.y) > 0 &&
            (currPoint.z - curr.z) / (next.z - curr.z) < 1.0 &&
            (currPoint.z - curr.z) / (next.z - curr.z) > 0
          ) {
            nextIn.push(currPoint);
          }
        }
        if (nextIn.length > 1) {
          return true;
        } else {
          let verticalIn = [];
          let vTop = new THREE.Vector3(next.x, next.y, 1.8),
            vBottom = new THREE.Vector3(next.x, next.y, 0.2);
          let verticalIntersects = this.getObjectsByRay([vTop, vBottom]);
          if (verticalIntersects.length != 0) {
            for (let i = 0; i < verticalIntersects.length; i++) {
              let height = verticalIntersects[i].point.z;
              if (height > 0.25 && height < 2.0) {
                verticalIn.push(verticalIntersects[i].point);
                break;
              }
            }
            if (verticalIn.length > 0) {
              return true;
            }
          }
        }
      }
    },
    addBase() {
      let loader = new GLTFLoader();
      // if (this.decoderPath) {
      //   THREE.DRACOLoader.setDecoderPath(this.decoderPath);
      loader.setDRACOLoader(new DRACOLoader());
      // }
      const me = this;
      loader.load("model/scene.glb", function (object) {
        let model = object.scene || object;
        model.rotation.x = (90 * Math.PI) / 180;
        model.rotation.y = (35 * Math.PI) / 180;
        model.rotation.z = (0 * Math.PI) / 180;
        model.scale.set(1.5, 1.5, 1.5);
        me.scene.add(model);
        let loadingBar = document.getElementsByClassName("loadingBar")[0];
        loadingBar.parentNode.removeChild(loadingBar);
      });
    },

    mouseDown(evt) {
      console.log(evt, "evt");
      this.mouseHasMove = false;
    },
    mouseMove(evt) {
      if (Math.abs(evt.movementX) < 2 && Math.abs(evt.movementY) < 2) return;
      this.mouseHasMove = true;
    },
    //引入3d模型
    addWalkingMan() {
      let loader = new GLTFLoader();

      const me = this;
      loader.load("model/Xbot.glb", function (object) {
        let model = object.scene || object;

        model.traverse(function (child) {
          if (child.isMesh) {
            child.material.emissive = child.material.color;
            child.material.emissiveMap = child.material.map;
          }
        });
        me.setFrustumCulled(model);
        model.rotation.x = (90 * Math.PI) / 180;
        model.rotation.y = (me.standRotation[2] * Math.PI) / 180; //
        model.rotation.z = 0;
        model.position.x = me.standPosition[0];
        model.position.y = me.standPosition[1];
        model.position.z = me.standPosition[2];
        me.scene.add(model);
        model.scale.set(3, 3, 3);
        me.walkingman = model;
        let animation = object.animations && object.animations[6]; //1 stop 3 run
        let stopAnimation = object.animations && object.animations[2];
        if (animation && stopAnimation) {
          model.mixer = new THREE.AnimationMixer(model);
          console.log(model.mixer, "model.mixer");
          me.mixers.push(model.mixer); //AnimationMixer
          me.walkingAnimation = model.mixer.clipAction(animation);
          me.setWeight(me.walkingAnimation, 0);
          me.walkingAnimation.play();
          me.stopAnimation = model.mixer.clipAction(stopAnimation);
          me.setWeight(me.stopAnimation, 1);
          me.stopAnimation.play();
        }
      });
    },
    //   创建场景对象Scene
    initScene() {
      this.scene = new THREE.Scene();
    },
    //   相机
    initCamera() {
      let container = document.getElementById("container");
      this.camera = new THREE.PerspectiveCamera(
        45,
        container.clientWidth / container.clientHeight,
        0.01,
        10000
      ); //第一个参数为渲染角度，人眼一般设置为40-50
      this.camera.up.set(0, 0, 1);
    },
    //地板
    initPlane() {
      var planeGeometry = new THREE.PlaneGeometry(600, 600);
      //平面使用颜色为0xcccccc的基本材质
      var planeMaterial = new THREE.MeshBasicMaterial({ color: 0xcccccc });
      var plane = new THREE.Mesh(planeGeometry, planeMaterial);
      //设置屏幕的位置和旋转角度
      // plane.rotation.x = -0.5 * Math.PI;
      plane.position.x = 0;
      plane.position.y = 0;
      plane.position.z = 0;
      //将平面添加场景中
      this.scene.add(plane);
    },
    //创建渲染器对象
    initWebGLRenderer() {
      this.container = document.getElementById("container");
      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      // this.renderer.autoClear = false;
      this.renderer.setClearColor(0xb9d3ff, 1); //设置背景颜色
      this.renderer.sortObjects = false;
      this.renderer.setSize(
        this.container.clientWidth,
        this.container.clientHeight
      ); //设置渲染区域尺寸
      this.container.appendChild(this.renderer.domElement); //body元素中插入canvas对象
    },
    //辅助三维坐标系AxisHelper
    initAxisHelper() {
      this.axesHelper = new THREE.AxesHelper(250);
      this.scene.add(this.axesHelper);
    },
    render() {
      this.tween.update();
      this.control.update();
      this.updateAnimation();
      this.renderer.render(this.scene, this.camera); //执行渲染操作
      requestAnimationFrame(this.render); //请求再次执行渲染函数render
    },
    //  初始化光照
    initLight() {
      let hemisphereLight = new THREE.HemisphereLight("#ffffff", "#666666", 1);
      hemisphereLight.position.set(0, 500, 300);
      this.scene.add(hemisphereLight);
    },
  },
  mounted() {
    this.init();
    //  this.addBase();
    this.addWalkingMan();
    // this.render();
  },
};
</script>
<style scoped>
#container {
  height: 400px;
}
</style>
